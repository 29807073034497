import { Component, Input, OnInit } from '@angular/core';
import { TableColumn } from 'src/app/modules/shared/components/table/table.model';
import { MultiSelectChangeEvent } from 'primeng/multiselect';

@Component({
  selector: 'app-multi-select-hidden-columns',
  templateUrl: './multi-select-hidden-columns.component.html',
  styleUrls: ['./multi-select-hidden-columns.component.scss'],
})
export class MultiSelectHiddenColumnsComponent implements OnInit {
  @Input({ required: true }) columns: TableColumn[] = [];
  @Input() table: string = '';
  @Input() showHiddenColumnLabels = false;

  protected hiddenColumns: TableColumn[] = [];

  get visibleColumns() {
    return this.columns.filter(column => column.visible);
  }

  isPanelOpen = false;

  ngOnInit() {
    this.hiddenColumns = this.columns.filter(column => !column.visible);
  }

  onChange(event: MultiSelectChangeEvent) {
    event.value.forEach((x: TableColumn) => {
      x.visible = false;
    });
    this.columns
      .filter(c => !event.value.includes(c))
      .forEach(c => (c.visible = true));
  }
}
