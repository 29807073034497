import { Component, Input } from '@angular/core';
import { Player } from 'src/app/models/entities/player';
import { PlayerProfileDialogService } from 'src/app/modules/shared/services/player-profile-dialog/player-profile-dialog.service';

@Component({
  selector: 'app-player-id-column',
  templateUrl: './player-id-column.component.html',
  styleUrl: './player-id-column.component.scss',
})
export class PlayerIdColumnComponent {
  @Input() player!: Player;
  @Input() open?: (p: Player) => Player;

  constructor(private playerProfileDialogService: PlayerProfileDialogService) {}

  openProfile(event: MouseEvent) {
    event.stopPropagation();
    if (this.open) this.player = this.open(this.player);
    this.playerProfileDialogService.openPlayerProfile(this.player);
  }
}
