import { gql } from 'apollo-angular';

export const GAME_DAY_INFO_FRAGMENT = gql`
  fragment GameDayInfo on GameDayInfo {
    __typename
    playerCount
    presentPlayers
    newPlayers
    bBCounts {
      bBs
      count
    }
    primaryCount
    backupCount
    packageCount

    squads {
      squad {
        id
        name
        colorHex
      }
      playerCount
    }
  }
`;
