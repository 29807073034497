<p-cellEditor *ngIf="inlineEdit; else output">
  <ng-template pTemplate="input">
    <ng-template *ngTemplateOutlet="inputTemplate"></ng-template>
  </ng-template>
  <ng-template pTemplate="output">
    <ng-template *ngTemplateOutlet="outputTemplate"></ng-template>
  </ng-template>
</p-cellEditor>

<ng-template #output>
  <ng-template *ngTemplateOutlet="outputTemplate"></ng-template>
</ng-template>
