import {
  Component,
  ContentChild,
  Directive,
  Input,
  TemplateRef,
} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[columnContentInput]',
})
export class ColumnContentInputDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[columnContentOutput]',
})
export class ColumnContentOutputDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
  selector: 'app-column-content',
  templateUrl: './column-content.component.html',
  styleUrl: './column-content.component.scss',
})
export class ColumnContentComponent {
  @Input({ required: true }) inlineEdit!: boolean;

  @ContentChild(ColumnContentInputDirective, { read: TemplateRef })
  inputTemplate!: TemplateRef<never>;
  @ContentChild(ColumnContentOutputDirective, { read: TemplateRef })
  outputTemplate!: TemplateRef<never>;
}
