import { Apollo } from 'apollo-angular';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { Injectable } from '@angular/core';
import {
  AddNode,
  ComponentDataService,
  DeleteNode,
  EditNode,
} from 'src/app/modules/shared/base/component.data.service';
import { GameDay } from 'src/app/models/entities/game-day';
import {
  ADD_GAME_DAYS,
  DELETE_GAME_DAYS,
  EDIT_GAME_DAYS,
  GET_GAME_DAYS,
} from 'src/app/models/graphql/game-days.query';
import { catchError } from 'rxjs/operators';
import { format } from 'date-fns';
import { TableService } from 'src/app/modules/shared/components/table/table.service';
import { QueryResult } from 'src/app/models/entities/query-result';
import { Filter } from 'src/app/models/graphql/filter/filter.model';
import { Sort } from 'src/app/models/graphql/filter/sort.model';

@Injectable({
  providedIn: 'root',
})
export class GameDaysDataService
  extends ComponentDataService
  implements AddNode<GameDay>, EditNode<GameDay>, DeleteNode<GameDay>
{
  constructor(
    private spinner: SpinnerService,
    private apollo: Apollo,
    private table: TableService
  ) {
    super();
  }

  load() {
    return this.apollo
      .query<{
        gameDays: QueryResult<GameDay>;
      }>({
        query: GET_GAME_DAYS,
        variables: {
          filter: this.table.filter$.value,
          sort: this.table.sort$.value,
          skip: this.table.skip$.value,
          take: this.table.take$.value,
        },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          throw err;
        })
      );
  }

  customLoad(config: {
    filter?: Filter[];
    sort?: Sort[];
    skip?: number;
    take?: number;
  }) {
    return this.apollo
      .query<{
        gameDays: QueryResult<GameDay>;
      }>({
        query: GET_GAME_DAYS,
        variables: {
          filter: config.filter ?? [],
          sort: config.sort ?? [],
          skip: config.skip,
          take: config.take,
        },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          throw err;
        })
      );
  }

  addNode(object: GameDay) {
    return this.apollo
      .mutate({
        mutation: ADD_GAME_DAYS,
        variables: {
          input: {
            date: format(object.date, 'yyyy-MM-dd'),
            comment: object.comment ?? '',
            active: object.active ?? false,
            public: object.public ?? false,
            locked: object.locked ?? false,
            player: [],
          },
        },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          this.table.load();
          throw err;
        })
      );
  }

  editNode(object: GameDay) {
    return this.apollo
      .mutate({
        mutation: EDIT_GAME_DAYS,
        variables: {
          id: object.id,
          input: {
            date: format(object.date, 'yyyy-MM-dd'),
            comment: object.comment,
            active: object.active ?? false,
            public: object.public ?? false,
            locked: object.locked ?? false,
          },
        },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          this.table.load();
          throw err;
        })
      );
  }

  deleteNode(object: GameDay) {
    return this.apollo
      .mutate({
        mutation: DELETE_GAME_DAYS,
        variables: {
          ids: [object.id],
        },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          this.table.load();
          throw err;
        })
      );
  }
}
