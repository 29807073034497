import { Filter } from 'src/app/models/graphql/filter/filter.model';
import { Sort } from 'src/app/models/graphql/filter/sort.model';
import { BehaviorSubject, take } from 'rxjs';
import { PersonalSettingsService } from 'src/app/modules/shared/services/personal-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitter, Injectable } from '@angular/core';
import { Logger } from 'src/app/services/logger/logger.service';

const log = new Logger('tableService');

@Injectable({
  providedIn: 'root',
})
export class TableService {
  protected useQueryParams = false;

  filter$ = new BehaviorSubject<Filter[]>([]);
  sort$ = new BehaviorSubject<Sort[]>([]);
  skip$ = new BehaviorSubject<number>(0);
  take$ = new BehaviorSubject<number>(this.rowsPerPageOptions[0]);
  onLoad = new EventEmitter<void>();

  get rowsPerPageOptions() {
    return [25, 50]; // TODO: Fix Data loading for: , 100, 250, 500];
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private personalSettings: PersonalSettingsService
  ) {
    this.skip$.subscribe(() => {
      this.setQueryParams();
    });
    this.take$.subscribe(x => {
      // Part of TEMP table data load temp fix
      if (x > 50) {
        this.take$.next(50);
        return;
      }

      this.personalSettings.setSetting(
        'table_take',
        x ?? this.rowsPerPageOptions[0]
      );
      this.setQueryParams();
    });
    this.sort$.subscribe(() => {
      this.setQueryParams();
    });
    this.filter$.subscribe(() => {
      this.setQueryParams();
    });
  }

  load() {
    this.onLoad.emit();
  }

  reset(args?: { withQueryParams: boolean }) {
    log.debug('Reset', args);
    this.useQueryParams = args?.withQueryParams ?? false;
    if (!this.useQueryParams) {
      this.clearQueryParams();
    }

    this.resetSkip();
    this.resetTake();
    this.resetSortAndFilter();
  }

  private resetSkip() {
    if (!this.useQueryParams) {
      this.skip$.next(0);
      return;
    }

    this.skip$.next(this.getQueryValueFromParams('skip') ?? 0);
  }

  private resetTake() {
    const take = this.useQueryParams
      ? this.getQueryValueFromParams('take')
      : undefined;
    if (take) {
      this.take$.next(take);
    } else {
      this.personalSettings.getSetting('table_take').then(x => {
        this.take$.next(x ?? this.rowsPerPageOptions[0]);
      });
    }
  }

  private resetSortAndFilter() {
    this.sort$.next(this.getQueryValueFromParams('sort') ?? []);
    this.filter$.next(this.getQueryValueFromParams('filter') ?? []);
  }

  protected getQueryValueFromParams(value: string) {
    const params = this.route.snapshot.queryParamMap;
    const queryValue = params.get(value);
    return queryValue ? JSON.parse(queryValue) : undefined;
  }

  protected clearQueryParams() {
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: {
          skip: undefined,
          take: undefined,
          sort: undefined,
          filter: undefined,
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
        replaceUrl: true,
      })
      .then();
  }

  protected setQueryParams() {
    if (!this.useQueryParams) return;
    log.debug('SetQueryParams');
    log.trace('skip: ', this.skip$.value);
    log.trace('take: ', this.take$.value);
    log.trace('sort: ', this.sort$.value);
    log.trace('filter: ', this.filter$.value);
    this.route.paramMap.pipe(take(1)).subscribe(params => {
      if (
        params.get('skip') !== this.skip$.value.toString() ||
        params.get('take') !== this.take$.value.toString() ||
        params.get('sort') !== JSON.stringify(this.sort$.value) ||
        params.get('filter') !== JSON.stringify(this.filter$.value)
      )
        this.router
          .navigate([], {
            relativeTo: this.route,
            queryParams: {
              skip: this.skip$.value,
              take: this.take$.value,
              sort:
                this.sort$.value.length > 0
                  ? JSON.stringify(this.sort$.value)
                  : undefined,
              filter:
                this.filter$.value.length > 0
                  ? JSON.stringify(this.filter$.value)
                  : undefined,
            },
            queryParamsHandling: 'merge', // remove to replace all query params by provided
            replaceUrl: true,
          })
          .then();
    });
  }
}
