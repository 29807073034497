import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MutationResult } from 'apollo-angular';
import { Filter } from 'src/app/models/graphql/filter/filter.model';
import { Sort } from 'src/app/models/graphql/filter/sort.model';

@Injectable({
  providedIn: 'root',
})
export abstract class ComponentDataService {
  abstract load(
    filter: Filter[],
    sort: Sort[],
    skip: number,
    take: number,
    id?: number
  ): void;
}

export interface AddNode<T> {
  addNode(object: T): Observable<T> | Observable<MutationResult>;
}

export interface EditNode<T> {
  editNode(object: T): Observable<T> | Observable<MutationResult>;
}

export interface DeleteNode<T> {
  deleteNode(object: T): Observable<T> | Observable<MutationResult>;
}
