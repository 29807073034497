import { Injectable } from '@angular/core';
import { DataService } from 'src/app/modules/shared/services/data/data.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

export type TaggedPlayer = {
  id: number;
  callSign: string;
};
export type PlayerIdCallback = (p?: TaggedPlayer) => void;

@Injectable({
  providedIn: 'root',
})
export class WebNfcService {
  isNFCActive$ = new BehaviorSubject<boolean>(false);
  callback?: PlayerIdCallback;

  constructor(
    private data: DataService,
    private toast: ToastService,
    private t: TranslateService
  ) {}

  listen(callback: PlayerIdCallback) {
    this.callback = callback;
    this.isNFCActive$.next(true);
  }

  reset() {
    this.isNFCActive$.next(false);
    this.callback = undefined;
  }

  async scanForPlayer() {
    if (!Object.prototype.hasOwnProperty.call(window, 'NDEFReader')) {
      this.toast.warn(
        this.t.instant('nfc.not_allowed.header'),
        this.t.instant('nfc.not_allowed.message')
      );
      this.isNFCActive$.next(false);
      return;
    }
    const ndef = new NDEFReader();
    await ndef.scan();
    this.isNFCActive$.next(false);

    ndef.addEventListener('readingerror', () => {
      this.toast.error(
        this.t.instant('nfc.error.header'),
        this.t.instant('nfc.error.message')
      );
    });

    ndef.addEventListener('reading', (e: Event) => {
      const ndefEvent = e as NDEFReadingEvent;
      this.data
        .findPlayerByRFIDTag(ndefEvent.serialNumber.replaceAll(':', ''))
        .subscribe(player => {
          if (!player) {
            if (this.callback) this.callback(undefined);

            return;
          }
          if (this.callback) this.callback(player);
        });
    });
  }
}
