<div class="flex justify-center items-center gap-15 game-day-info">
  <div *ngIf="canOverwriteActiveGameDayFilter" class="game-day-filter">
    <p-dropdown
      dataKey="id"
      class="w-60"
      styleClass="w-full"
      [ngModel]="filterGameDay"
      (onChange)="filterGameDayChange.emit($event.value)"
      [options]="gameDays"
      placeholder="{{ 'common.game_day' | translate }}"
      emptyMessage="{{ 'common.no_entries_found' | translate }}"
      [filter]="true"
      filterBy="date,comment"
      [showClear]="true"
      (onClear)="clear()">
      <ng-template let-value pTemplate="item">
        <i [class]="value.active ? 'pi pi-check-circle' : 'pi pi-times-circle'">
        </i>
        {{ value.date }}
        <span *ngIf="value.comment"> - {{ value.comment }}</span>
      </ng-template>
      <ng-template let-value pTemplate="selectedItem">
        <i [class]="value.active ? 'pi pi-check-circle' : 'pi pi-times-circle'">
        </i>
        {{ value.date }}
        <span *ngIf="value.comment"> - {{ value.comment }}</span>
      </ng-template>
    </p-dropdown>
  </div>

  <div class="orga-filter">
    <p-triStateCheckbox
      [ngModel]="filterOrga"
      inputId="binary"
      (onChange)="filterOrgaChange.emit($event.value)"
      label="{{ 'common.orga' | translate }}" />
  </div>

  <div class="present-players flex gap-15">
    <div>
      <span class="info">{{ gameDayInfo.presentPlayers }}</span>
      {{ 'common.of' | translate }}
      <span class="hd-1">{{ gameDayInfo.playerCount }}</span>
      {{ 'attendance_state.PRESENT' | translate }}
    </div>
    <div>
      {{ 'common.new' | translate }}:
      <span class="warning">{{ gameDayInfo.newPlayers }}</span>
    </div>
  </div>

  <div *ngIf="showSquads" class="squads flex gap-10">
    <div *ngFor="let squad of gameDayInfo.squads">
      <span *ngIf="squad?.squad" style="color: {{ squad.squad.colorHex }}">
        {{ squad.squad.name | FirstLetter }}: {{ squad.playerCount }}
      </span>
    </div>
  </div>

  <div *ngIf="showBBs">
    <div class="bbs flex gap-10">
      {{ 'common.bbs' | translate }}:
      <div *ngFor="let bb of gameDayInfo.bBCounts">
        <div>
          <span class="hd-1">{{ bb.bBs }}: </span>
          <span class="info">{{ bb.count }}x</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showRentals">
    <div class="bbs flex gap-10">
      {{ 'common.rentals' | translate }}:
      <div>
        <span class="hd-1">{{ 'game_day.info.packages' | translate }}: </span>
        <span class="info">{{ gameDayInfo.packageCount }}</span>
      </div>
      <div>
        <span class="hd-1">{{ 'common.primary' | translate }}: </span>
        <span class="info">{{ gameDayInfo.primaryCount }}</span>
      </div>
      <div>
        <span class="hd-1">{{ 'common.backup' | translate }}: </span>
        <span class="info">{{ gameDayInfo.backupCount }}</span>
      </div>
    </div>
  </div>
</div>
