<div *ngIf="player">
  <p-dialog
    [(visible)]="visible"
    [modal]="true"
    [draggable]="true"
    [style]="{ width: '50vw' }">
    <ng-template pTemplate="header">
      <h1>{{ player.callSign }}</h1>
    </ng-template>
    <ng-template pTemplate="content">
      <div *ngIf="player.aggregatedStats">
        <div class="grid-container">
          <ng-container *ngFor="let attr of aggregatedStatsAttributes">
            <ng-container *ngIf="player.aggregatedStats[attr.property]">
              <div class="grid-item label">
                {{ 'player.aggregated_stats.' + attr.TKey | translate }}:
              </div>
              <div class="grid-item value">
                {{ player.aggregatedStats[attr.property] }}
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </p-dialog>
</div>
