import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Logger } from 'src/app/services/logger/logger.service';

const logger = new Logger('GuiService');

@Injectable({
  providedIn: 'root',
})
export class GuiService {
  isMobile$ = new BehaviorSubject<boolean>(this.isMobileByWindowWith());
  isTablet$ = new BehaviorSubject<boolean>(this.isTabletByWindowWith());

  constructor() {}

  private isMobileByWindowWith(): boolean {
    return window.innerWidth <= 600;
  }

  private isTabletByWindowWith(): boolean {
    return window.innerWidth <= 960;
  }

  public checkWindowSize() {
    logger.debug('check window size', {
      mobile: this.isMobileByWindowWith(),
      tablet: this.isTabletByWindowWith(),
      size: window.innerWidth,
    });

    const isMobile = this.isMobileByWindowWith();
    if (isMobile) {
      this.isMobile$.next(true);
      this.isTablet$.next(false);
      return;
    }
    this.isTablet$.next(this.isTabletByWindowWith());
    this.isMobile$.next(false);
  }

  public hexToRGB(hex: string, alpha: number): string {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }
}
