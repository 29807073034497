<p-dialog
  styleClass="custom-dialog"
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '50vw', height: '75vh' }">
  <ng-template pTemplate="header">
    <h1>Dev console</h1>
  </ng-template>
  <div class="h-full">
    <p-terminal
      [prompt]="prompt"
      [welcomeMessage]="welcomeMessage"
      styleClass="h-full" />
  </div>
</p-dialog>
