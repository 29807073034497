import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, map } from 'rxjs';
import { Logger } from 'src/app/services/logger/logger.service';

const log = new Logger('AuthGuard');

@Injectable()
export class AuthGuard {
  constructor(public oidcSecurityService: OidcSecurityService) {}

  canActivate(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated().pipe(
      map(isAuthenticated => {
        log.debug('User Authenticated', isAuthenticated);
        return isAuthenticated;
      })
    );
  }
}
