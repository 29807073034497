import { Injectable } from '@angular/core';
import { Command } from 'src/app/modules/shared/components/terminal/terminal.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TerminalCommandService {
  _commands$ = new BehaviorSubject<Command[]>([]);
  get commands$() {
    return this._commands$;
  }

  constructor() {}

  addCommand(command: Command) {
    const commands = this.commands$.value;
    commands.push(command);
    this._commands$.next(commands);
  }

  addCommands(commands: Command[]) {
    const commandsValue = this.commands$.value.concat(commands);
    this._commands$.next(commandsValue);
  }
}
