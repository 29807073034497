import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IdentityService } from 'src/app/services/identity/identity.service';
import { IdentityUser } from 'src/app/models/entities/identity-user';
import { Subject, Subscription } from 'rxjs';
import { TerminalService } from 'primeng/terminal';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { TerminalCommandService } from 'src/app/modules/shared/components/terminal/terminal-command.service';
import { takeUntil } from 'rxjs/operators';

export interface Command {
  name: string;
  alias?: string;
  description: string;
  command: () => string;
}

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrl: './terminal.component.scss',
})
export class TerminalComponent implements OnInit, OnDestroy {
  private _visible: boolean = false;

  @Input()
  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(this._visible);
    if (value) {
      this.setPrompt();
    }
  }

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public welcomeMessage = '';
  protected prompt: string = 'admin-tool:~$';
  protected user?: IdentityUser;
  private subscription: Subscription;
  private unsubscriber$ = new Subject<void>();
  public frontendVersion: string = '0.0.0.0';

  commands: Command[] = [];

  constructor(
    private auth: IdentityService,
    private terminalService: TerminalService,
    private settings: SettingsService,
    private terminalCommands: TerminalCommandService
  ) {
    this.terminalCommands.commands$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(x => {
        this.commands = [
          {
            name: 'exit',
            description: 'Closes the console',
            command: () => {
              this.visible = false;
              return 'bye';
            },
          },
          ...x,
        ];

        this.commands = [
          {
            name: 'help',
            description: 'Shows this help message',
            command: () => {
              let message = 'Available commands:\n\n';

              this.commands.forEach(command => {
                message += `${command.name}${command.alias ? ` (${command.alias})` : ''} - ${command.description}\n`;
              });

              message += '\n';

              return message;
            },
          },
          ...this.commands,
        ];
      });

    this.subscription = this.terminalService.commandHandler.subscribe(
      command => {
        const response =
          this.commands
            .find(x => x.name === command || (x.alias && x.alias === command))
            ?.command() ?? 'Unknown command: ' + command;
        this.terminalService.sendResponse(response);
      }
    );
  }

  ngOnInit() {
    this.frontendVersion = this.settings.getWebVersion();
    this.setWelcomeMessage();
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setPrompt() {
    if (this.auth.identityUser$.value) {
      this.user = this.auth.identityUser$.value;
      this.prompt = `${this.user.Firstname}@${this.prompt}`;
    }
  }

  setWelcomeMessage() {
    this.welcomeMessage = `
               ((         Admin Tool @ Area49
               ( \`)      ------------------------------
               ; / ,      Version: ${this.frontendVersion}
              /  \\/       Developed by: Kroete, Donkoleon & edraft
             /  |         Started: 28. Feb. 2024, 7:38 PM MEZ
            /  ~/        Used languages: TypeScript, HTML, SCSS, C# & SQL
           / )  )
    ___// | /
    \`--'  \\_~-,
  `;
  }
}
