import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GameDay, GameDayInfo } from 'src/app/models/entities/game-day';
import { GameDaysDataService } from 'src/app/modules/admin/game-days/game-days.data.service';
import { IdentityService } from 'src/app/services/identity/identity.service';
import { SortOrder } from 'src/app/models/graphql/filter/sort.model';

@Component({
  selector: 'app-gameday-info',
  templateUrl: './gameday-info.component.html',
  styleUrl: './gameday-info.component.scss',
})
export class GamedayInfoComponent implements OnInit {
  @Input({ required: true }) gameDayInfo!: GameDayInfo;
  @Input() filterOrga: boolean | null | undefined = false;
  @Output() filterOrgaChange = new EventEmitter<boolean | null | undefined>();

  @Input() filterGameDay: GameDay | null | undefined;
  @Output() filterGameDayChange = new EventEmitter<GameDay | undefined>();

  @Input() showSquads = true;
  @Input() showBBs = false;
  @Input() showRentals = false;

  protected canOverwriteActiveGameDayFilter = false;
  protected gameDays: GameDay[] = [];

  constructor(
    private identity: IdentityService,
    private gameDaysService: GameDaysDataService
  ) {}

  async ngOnInit() {
    const user = await this.identity.getLoggedInUser();
    this.canOverwriteActiveGameDayFilter = this.identity.hasUserPermission(
      user,
      'game_days.overwrite_active_filter'
    );
    this.load();
  }

  load() {
    if (!this.canOverwriteActiveGameDayFilter) return;
    this.gameDaysService
      .customLoad({
        sort: [{ active: SortOrder.DESC }, { date: SortOrder.DESC }],
      })
      .subscribe(gameDays => {
        this.gameDays = gameDays.data.gameDays.nodes;
        if (!this.filterGameDay)
          this.filterGameDayChange.emit(
            this.gameDays.find(x => x.active) ?? this.gameDays[0]
          );
      });
  }

  clear() {
    this.load();
  }
}
