import { Pipe, PipeTransform } from '@angular/core';

type Input = {
  [key: string]: unknown;
};

@Pipe({ name: 'map' })
export class MapPipe implements PipeTransform {
  transform(input: Input[], key: string): unknown[] {
    return input.map(value => value[key]);
  }
}
