import { gql } from 'apollo-angular';

export const GET_BBS = gql`
  query ($filter: [BbFilter!]!, $sort: [BbSort!]!, $skip: Int, $take: Int) {
    shop {
      bbs {
        bbs(filter: $filter, skip: $skip, sort: $sort, take: $take) {
          totalCount
          count
          nodes {
            id
            name
            weight
            price
            public
            favorite
          }
        }
      }
    }
  }
`;

export const ADD_BBS = gql`
  mutation ($input: BbCreate!) {
    shop {
      bbs {
        create(bb: $input) {
          id
          name
          weight
          price
          public
          favorite
        }
      }
    }
  }
`;

export const EDIT_BBS = gql`
  mutation ($input: BbEdit!) {
    shop {
      bbs {
        edit(bb: $input) {
          id
          name
          name
          weight
          price
          public
          favorite
        }
      }
    }
  }
`;

export const DELETE_BBS = gql`
  mutation ($id: Int!) {
    shop {
      bbs {
        delete(id: $id)
      }
    }
  }
`;
